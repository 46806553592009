<template>
  <div class="pb-2">
    <div class="row justify-content-md-center">
      <div class="col-6">
        <div class="input-group">
          <input class="form-control"
                 name="search-video-term"
                 type="text"
                 placeholder="Search"
                 autocomplete="off"
                 v-model="filterText"
                 @keyup.enter="doFilter" />
          
          <div class="input-group-append">
            <button class="btn btn-primary"
                    type="submit"
                    @click="doFilter">
              <i class="fas fa-search"></i>
            </button>
          </div>
          
          <button class="btn btn-outline-primary ml-2"
                  type="button"
                  @click="resetFilter">
            <i class="fas fa-sync-alt"></i>
          </button>
          
          <button class="btn btn-outline-success btn-sm ml-2"
                  type="button"
                  @click="addCategory">
            <i class="fas fa-plus"></i> Add Category
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  const AddCategory =
    () => import('@/components/admin/category/video/AddCategory.vue');

  export default {
    name : 'FilterBar',
    data() {
      return {
        filterText : '',
      }
    },
    methods : {

      /**
       * Filter action
       */
      doFilter() {
        this.$events.fire('filter-set-category', this.filterText);
      },

      /**
       * Reset Filter
       */
      resetFilter() {
        this.filterText = '';  // clear the text in text input
        this.$events.fire('filter-reset-category');
      },

      /**
       * Show Add Category modal
       */
      addCategory() {
        this.$modal.show(AddCategory, {}, {
          height   : 'auto',
          adaptive : true,
        }, {
          'reload-table' : (event) => {
            this.$events.fire('reload');
          },
        });
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import
    "../../../../assets/scss/components/admin/category/video/filter-bar";
</style>